import { useContext } from "react";
import { LangContext } from "../../utils/langContext";
import { MouseContext } from "../../utils/mouseContext";
import { ImEarth } from 'react-icons/im';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

const Footer = ({ light = false }) => {
    const langContext = useContext(LangContext)
    const { cursorChangeHandler } = useContext(MouseContext)

    const { t, i18n } = useTranslation()

    return (
        <div className={`footer-wrapper ${light && "light"}`}>
            <div className={`footer-container ${light && "light"} content-margin-wrapper`}>
                <div className="footer-content-container">
                    <div className="footer-left-container">
                        <div className="footer-left-logo-container">
                            <img onClick={() => window.location.replace("/")} onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} width={200} src={(light ? require("../../assets/images/logo_big_dark.svg") : require("../../assets/images/logo_big.svg")).default} className="footer-left-logo" />
                        </div>

                        <div className="footer-language-selector-wrapper">
                            <div className="footer-language-selector-container">
                                <div className="footer-language-selector-title">
                                    {langContext.langs.find((item) => item.code == i18n.language)?.title} <ImEarth style={{ marginLeft: 10 }} />
                                </div>

                                <select className="footer-language-selector-select" value={i18n.language} onChange={(e) => {
                                    i18n.changeLanguage(e.currentTarget.value)
                                    window.location.reload()
                                }}>
                                    {
                                        langContext.langs.map((item, idx) => (
                                            <option value={item.code}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="footer-right-container">
                        <div className="footer-right-resources-container">
                            <p className="footer-right-resources-header">
                                {parse(t("footer_resources_header_2"))}
                            </p>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/' className="footer-right-resource-item">
                                {parse(t("footer_route_1"))}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/about' className="footer-right-resource-item">
                                {parse(t("footer_route_2"))}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/support' className="footer-right-resource-item">
                                {parse(t("footer_route_3"))}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/support/message' className="footer-right-resource-item">
                                {parse(t("footer_route_4"))}
                            </a>
                        </div>

                        <div className="footer-right-resources-container download">
                            <p className="footer-right-resources-header">
                                {parse(t("footer_resources_header_1"))}
                            </p>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://apps.apple.com/id/app/matreshka-vpn/id1622803257' className="footer-right-resource-item">
                                iOS
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/download/android' className="footer-right-resource-item">
                                Android
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://apps.apple.com/id/app/matreshka-vpn/id1622803257' className="footer-right-resource-item">
                                macOS
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/download/windows' className="footer-right-resource-item">
                                Windows
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/download/android-tv' className="footer-right-resource-item">
                                Android TV
                            </a>
                        </div>

                        <div className="footer-right-resources-container">
                            <p className="footer-right-resources-header">
                                {parse(t("footer_resources_header_3"))}
                            </p>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/oferta' className="footer-right-resource-item">
                                {parse(t("footer_resources_header_3_row_1"))}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/confidential' className="footer-right-resource-item">
                                {parse(t("footer_resources_header_3_row_2"))}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-container">
                    <p className="footer-bottom-item">
                        © 2023 {t("app_name")}<br />

                        ИП ДУВАНСКИЙ Д.А. ОГРНИП 324508100339510
                    </p>

                    <div>
                        <p className="footer-bottom-item">
                            support@matreshkavpn.com
                        </p>
                        <a href="https://t.me/matreshkavpn_official" className="footer-bottom-item">
                            {t("footer_telegram")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;