import axios, { Axios } from "axios";

const request = axios.create({
    baseURL: "https://api.matreshkavpn.com"
});

request.interceptors.request.use((conf) => {

    if(localStorage.getItem("token") != null) {
        conf.headers["Authorization"] = "Bearer " + localStorage.getItem("token")
    }

    return conf;
})

export default request;